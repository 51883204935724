<template>
  <div class="perbox2">
    <el-tabs @tab-click="setPath">
      <el-tab-pane label="我的视频"> </el-tab-pane>
      <el-tab-pane label="我的订阅"> </el-tab-pane>
      <el-tab-pane label="我的收藏"> </el-tab-pane>
    </el-tabs>
    <div class="miniBox">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
    };
  },
  methods: {
    setPath(val) {
      console.log(val.index);
      if (val.index == 0) {
        this.$router.push("/mPersonal/myVideo2");
      } else if (val.index == 1) {
        this.$router.push("/mPersonal/subs");
      } else if (val.index == 2) {
        this.$router.push("/mPersonal/collect");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.perbox2 {
  padding: 0 (18 / 3.75vw);
  .actList {
  }
  .miniBox {
  }
}
</style>
