<template>
  <div class="personal">
    <per-box-1></per-box-1>
    <per-box-2></per-box-2>
  </div>
</template>
<script>
import Store from "../../store";
import PerBox1 from "./per-box1.vue";
import perBox2 from "./per-box2.vue";
export default {
  components: { PerBox1, perBox2 },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
    };
  },
};
</script>
<style lang="less" scoped></style>
