<template>
  <div class="perbox1">
    <div class="user">
      <!-- <div class="avatar">{{ uti.setAvatar(store.user.nickName) }}</div> -->
      <avatar-item></avatar-item>
      <div class="text">
        <div class="name">{{ store.user.nickName }}</div>
        <div class="addr">{{ uti.shrink(store.user.address, 4) }}</div>
      </div>
      <div class="but" @click="updeteName()">修改昵称</div>
    </div>
    <div class="per-data">
      <div class="item">
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-guanzhu"></use>
        </svg>
        <div class="title" @click="uti.goPath('mFollows')">关注</div>
        <div class="num">{{ store.personalDec.followedNum }}</div>
      </div>
      <div class="lin"></div>
      <div class="item">
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-guanyuwomen"></use>
        </svg>
        <div class="title" @click="uti.goPath('mFollows')">粉丝</div>
        <div class="num">{{ store.personalDec.fansNum }}</div>
      </div>
      <div class="lin"></div>
      <div class="item">
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-jifenduihuan"></use>
        </svg>
        <div class="title" @click="uti.goPath('mIncome')">打赏收入</div>
        <div class="num">{{ store.personalDec.rewardsNum }}</div>
      </div>
      <div class="lin"></div>
      <div class="item">
        <svg class="icon svg-icon" aria-hidden="true">
          <use xlink:href="#icon-dianzan"></use>
        </svg>
        <div class="title">获赞</div>
        <div class="num">{{ store.personalDec.lovesNum }}</div>
      </div>
    </div>
    <up-name ref="upname"></up-name>
  </div>
</template>
<script>
import AvatarItem from "../../components/avatar/avatar-item.vue";
import upName from "../../components/personal/upName.vue";
import Store from "../../store";
export default {
  components: { upName, AvatarItem },
  name: "perBox1",
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      // 个人统计数据
      personalDec: "",
    };
  },
  mounted() {
    this.getPersonalDec();
  },
  methods: {
    // 打开修改昵称
    updeteName() {
      this.$refs.upname.up();
    },
    // 主页统计数据
    async getPersonalDec() {
      if (!this.store.user.userId) {
        return this.$message.warning("请先登录");
      }
      let { data: res } = await this.$http.post(
        `/userInfo/statistics/${this.store.user.userId}`
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      // console.log(res.data);
      this.store.personalDec = res.data;
    },
  },
};
</script>
<style lang="less" scoped>
.perbox1 {
  padding: 0 (18 / 3.75vw);
  .user {
    display: flex;
    align-items: center;
    .avatar {
      height: 40px;
      width: 40px;
      border-radius: 5px;
      background-color: cornflowerblue;
      text-align: center;
      line-height: 40px;
      color: #fff;
    }
    .text {
      padding-left: 10px;
      flex: 1;
      .name {
        /* Bill·Clinton */
        height: 20px;
        font-family: PingFang SC Bold;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #333333;
      }
      .addr {
        /* 0x004Aa...9573E7 */

        height: 17px;
        font-family: PingFang SC Regular;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #999999;
      }
    }
    .but {
      /* 矩形 4 */

      font-family: PingFang SC Medium;
      display: inline-block;
      padding: 6px 14px;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #ffffff;
      border-radius: 5px;
      background: #ff7d41;
      box-shadow: 0px 2px 3px 0px rgba(176, 176, 176, 0.5);
    }
  }
  .per-data {
    margin-top: (28 / 3.75vw);
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    .item {
      text-align: center;
      .icon {
        font-size: (20 / 3.75vw);
        margin: 0 auto;
      }
      .title {
        font-size: (12 / 3.75vw);
        color: #999;
      }
      .num {
        margin-top: (15 / 3.75vw);
        font-size: (20 / 3.75vw);
        font-weight: bold;
        color: #333;
      }
    }
    .lin {
      height: (27 / 3.75vw);
      width: 1px;
      background-color: #e8e8e8;
    }
  }
}
</style>
