<template>
  <div class="rewardBox">
    <el-dialog title="" :visible.sync="ifshow" center>
      <div class="box">
        <div class="user">
          <!-- <el-avatar shape="square" :size="170" :src="store.avatar"></el-avatar> -->
          <div class="wl-avatar">
            {{ uti.setAvatar(store.user.nickName) }}
          </div>
        </div>
        <div class="inputBox">
          <div class="key">昵称：</div>
          <el-input placeholder="请输入昵称" v-model="input"> </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateName()">确认修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Store from "../../store";
export default {
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      // 是否显示
      ifshow: false,
      act: "10",
      // 是否打开输入
      inputShow: false,
      input: "",
      web3: "",
      shangCont: "",
    };
  },
  methods: {
    up() {
      this.ifshow = true;
    },
    down() {
      this.ifshow = false;
    },
    // 选择某一个
    actItem(val) {
      this.act = val;
      if (val !== "zd") {
        this.input = val;
        this.inputShow = false;
      } else {
        this.input = "";
        this.inputShow = true;
      }
    },
    async updateName() {
      if (!this.store.user.userId) {
        return this.$message.warning("请先登录");
      }
      let { data: res } = await this.$http.post("/userInfo/nickName", {
        nickName: this.input,
        userId: this.store.user.userId,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.$message.success("修改成功");
      this.store.user.nickName = this.input;
      this.down();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  /* 矩形 20 */
  width: 607px;
  // width: 1107px;
  // height: 605px;
  padding-bottom: 10px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
  .box {
    // width: 747px;
    margin: 0 auto;
    .user {
      display: flex;
      align-items: center;
      justify-content: center;
      .wl-avatar {
        width: 170px;
        height: 170px;
        line-height: 170px;
        font-size: 90px;
      }
    }
    .inputBox {
      display: flex;
      margin-top: 60px;
      align-items: center;
    }
    .key {
    }
    .el-input {
      width: 1px;
      flex: 1;
      /* 矩形 23 */
      // height: 90px;
      // border-radius: 5px;
      box-sizing: border-box;
      // border: 1px solid #ff7d41;
      // box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid #000;

      input {
        // height: 90px;
        outline: none;
        padding-right: 20px;
        padding-left: 10px;
        font-size: 20px;
        border: none;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      // input:focus {
      //   border: 1px solid #ff7d41;
      // }
      .el-input__suffix {
        right: 50px;
        font-size: 20px;
      }
    }
  }
  .el-button {
    margin-top: 20px;
    width: 100%;
    height: 60px;
    background-color: #ff7d41;
    border: none;
    font-size: 24px;
  }
}
@media screen and (max-width: 700px) {
  /deep/ .el-dialog {
    /* 矩形 20 */
    width: 310px;
    // width: 1107px;
    // height: 605px;
    padding-bottom: 10px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
    .box {
      // width: 747px;
      margin: 0 auto;
      .user {
        display: flex;
        align-items: center;
        justify-content: center;
        .wl-avatar {
          width: 70px;
          height: 70px;
          line-height: 70px;
          font-size: 50px;
        }
      }
      .inputBox {
        display: block;
        margin-top: 30px;
        align-items: center;
      }
      .el-input {
        width: 100%;
        flex: 1;
        /* 矩形 23 */
        // height: 90px;
        // border-radius: 5px;
        box-sizing: border-box;
        // border: 1px solid #ff7d41;
        // box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid #000;

        input {
          // height: 90px;
          outline: none;
          padding-right: 0;
          padding-left: 0;
          font-size: 16px;
          border: none;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        // input:focus {
        //   border: 1px solid #ff7d41;
        // }
        .el-input__suffix {
          right: 50px;
          font-size: 16px;
        }
      }
    }
    .el-button {
      margin-top: 0px;
      width: 100%;
      height: 42px;
      // line-height: 42px;
      background-color: #ff7d41;
      border: none;
      font-size: 14px;
    }
  }
}
</style>
